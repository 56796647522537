import axios from 'axios';
import { BASE_API_URL } from '../conf/apiConfig';

export function introduceDelayInCreation() {
    console.log('baseurl'+BASE_API_URL)
    axios.get(`${BASE_API_URL}/api/v1/create/true`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Introducing delay in employee creation');
}

export function introduceDelayInUpdate() {
    axios.get(`${BASE_API_URL}/api/v1/update/true`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Introducing delay in employee updation');
}

export function revertCreationDelay() {
    axios.get(`${BASE_API_URL}/api/v1/create/false`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Reverting employee creation delay');
}

export function revertUpdateDelay() {
    axios.get(`${BASE_API_URL}/api/v1/update/false`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Reverting employee updation delay');
}

export function IntroduceSalaryMiddleWareDelay() {
    axios.post(`${BASE_API_URL}/actuator/chaosmonkey/enable/salarymiddleware`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Enabling delay in salary middleware');
}

export function revertSalaryMiddlewareDelay() {
    axios.post(`${BASE_API_URL}/actuator/chaosmonkey/disable/salarymiddleware`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Disabling delay in salary middleware');
}

export function IntroduceSalaryDelay() {
    axios.post(`${BASE_API_URL}/actuator/chaosmonkey/enable/salaryservice`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Enabling delay in salary service');
}

export function revertSalaryDelay() {
    axios.post(`${BASE_API_URL}/actuator/chaosmonkey/disable/salaryservice`)
        .then(response => console.log(response.data))
        .catch(error => {
            console.error('Error response:', error.response);
            console.error('Error message:', error.message);
        });
    console.log('Disabling delay in salary service');
}
